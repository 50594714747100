.field {
    width: 100%;
    margin-top: 32px;
}

.form-control {
    width: '100%';
    margin-top: 32px;
    display: 'flex';
        
}

.error {
    color: red;
}