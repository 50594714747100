@import url(https://fonts.googleapis.com/css?family=Raleway);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-float infinite 3s ease-in-out;
            animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@-webkit-keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}


.loading-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: darkcyan;
}

.react-spinner-loader-swing, .react-spinner-loader-shadow {
    display: flex;
    justify-content: center;
}



.field {
    width: 100%;
    margin-top: 32px;
}

.form-control {
    width: '100%';
    margin-top: 32px;
    display: 'flex';
        
}

.error {
    color: red;
}
.layout {
    width: 100%;
    display: flex;
    position: relative;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    min-height: 100vh;
    flex-direction: column;
}

.content {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
}

.main-content {
    display: flex;
    flex-grow: 1;
}

.authenticated-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
}

.hidden {
    display: none;
}
